<template>
  <div>
    <!-- 单个窗口 -->
    <div class="clapprs-container" v-if="reload">
      <!-- 使用窗口数amount遍历播放容器 -->
      <div
        v-for="(item, index) in amount"
        :key="item"
        ref="play"
        :class="{
          ['shade' + amount]: true,
          ['clapprs-item' + amount]: true,
          'current-window': item == currentIndex,
        }"
        @click="handleClick(item)"
      >
        <div :id="'play' + index"></div>
        <i class="close-icon" @click.stop="closePlay(item)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from "@/utils/utils";
import create from "../../create";
import EZUIKitCope from "./ezuikit.js"; // 定制播放器，去掉了视频的设备名称和编号

import { getProjectName, getVideoConfigTokenInfo } from "@/api/video";
import { validatenull } from "../../util";
export default create({
  name: "yunClapprsAuto",
  data() {
    return {
      hid: "main_" + uuid(),
      reload: true,
      config: {},
      playList: [],
      urlList: [],
      containerList: [],
      currentIndex: "",
      token: "",
      vList: [],
      player: [
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
        { p: null },
      ],
    };
  },
  computed: {
    /* 配置窗口显示个数 支持 1、4、6、9 */
    amount() {
      return Number(this.option.amount);
    },
  },
  watch: {
    amount(n) {
      this.changeWindow(n);
    },
  },
  mounted() {
    let that = this;
    // this.getToken();
    // this.getProjectNameData()
    this.$bus.$on("changeAmount", async (params) => {
      if (params.token) {
        that.token = params.token;
      } else if (validatenull(that.token)) {
        await that.getToken();
      }
      if (!validatenull(params.amount)) {
        that.amount = params.amount;
      }
      if (!validatenull(params.vList)) {
        that.vList = params.vList;
      }
      if (that.amount && that.token && params.vList) {
        that.initPlayer();
      }
    });
    // 手动执行替换事件监听
    this.$bus.$on("playMonitoring", function (params) {
      if (params.value) {
        that.handlePlay(params);
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("changeAmount");
    // this.$bus.$off("playMonitoring");
  },
  methods: {
    async getToken() {
      const res = await getVideoConfigTokenInfo();
      if (res.data.code === 200) {
        this.token = res.data.data;
      }
    },
    /* 获取项目名称 */
    async getProjectNameData() {
      const res = await getProjectName();
      if (res.data.code == 200) {
        this.projectName = res.data.data.simple;
      }
    },
    /* 初始化时加载6窗口的画面 */
    async initPlayer() {
      // 获取div的宽高
      let width = this.$refs.play[0].offsetWidth;
      let height = this.$refs.play[0].offsetHeight;
      // const res = await getplayVideoPage({
      //   projectId: this.$route.query.projectId,
      // });
      // if (res.data.code == 200) {
      // 获取监控播放地址url数组
      // const urlList = res.data.data[0].videoInfoVOList.splice(0, 6);
      // 遍历url数组，播放6个窗口画面
      this.vList.forEach((item, index) => {
        const player = new EZUIKitCope.EZUIKitPlayer({
          id: "play" + index,
          url: `ezopen://open.ys7.com/${item.deviceSerial}/${item.channelNo}.live`,
          accessToken: this.token,
          template: "pcLive",
          height,
          width,
        });
        this.player[index].p = player;
      });
      // this.urlList = urlList;
      // }
    },
    /* 切换窗口个数 */
    changeWindow(amount) {
      // 处理 playList 后再给this.amount赋值
      this.$nextTick(() => {
        // 获取div的宽高
        let width = this.$refs.play[0].offsetWidth;
        let height = this.$refs.play[0].offsetHeight;
        let _self = this;
        // 每次切换窗口个数时，保证playList的长度等于窗口个数，没有play对象的位置使用null填充
        for (let index = 0; index < amount; index++) {
          if (index > _self.vList - 1) {
            return;
          }
          const player = new EZUIKitCope.EZUIKitPlayer({
            id: "play" + index,
            url: `ezopen://open.ys7.com/${_self.vList[index].deviceSerial}/${_self.vList[index].channelNo}.live`,
            accessToken: _self.token,
            template: "pcLive",
            height,
            width,
          });
          _self.player[index].p = player;
        }
      });
    },
    /* 点击左侧列表执行播放替换操作 */
    handlePlay(params) {
      // 初始化未选中窗口时，点击列表某个监控，给this.currentIndex赋1
      if (!this.currentIndex) {
        this.$message.warning("替换前，请选择播放窗口");
      }
      let width = this.$refs.play[0]?.offsetWidth;
      let height = this.$refs.play[0]?.offsetHeight;
      let deviceSerial = params.value.split("-")[0];
      let channelNo = params.value.split("-")[1];
      const player = new EZUIKitCope.EZUIKitPlayer({
        id: "play" + (this.currentIndex - 1),
        url: `ezopen://open.ys7.com/${deviceSerial}/${channelNo}.live`,
        accessToken: this.token,
        template: "pcLive",
        height,
        width,
      });
      this.player[this.currentIndex - 1].p = player;
      this.currentIndex = "";
    },
    /* 点击某一个播放窗口 */
    handleClick(index) {
      // index 从1开始
      if (this.currentIndex == index) {
        this.currentIndex = "";
      } else {
        this.currentIndex = index;
      }
    },
    /* 关闭播放 */
    closePlay(index) {
      this.player[index - 1].p.stop();
      this.player[index - 1].p = null;
    },
  },
});
</script>
<style scoped lang="scss">
.current-window {
  box-sizing: border-box;
  // overflow: hidden;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    line-height: 88px;
    text-align: center;
    color: #419de6;
    border: 2px solid #419de6;
    z-index: 1;
  }
}
.clapprs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  // 单窗口样式
  .clapprs-item1 {
    position: relative;
    width: 100% !important;
    height: 100% !important;
  }
  .shade1 {
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
  }

  // 4窗口样式
  .clapprs-item4 {
    position: relative;
    width: calc(50% - 10px) !important;
    height: calc(50% - 10px) !important;
  }
  .shade4 {
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
  }
  // 6窗口样式
  .clapprs-item6 {
    position: relative;
    width: calc(50% - 10px) !important;
    height: calc(33.333% - 10px) !important;
  }
  .shade6 {
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
  }

  // 9窗口样式
  .clapprs-item9 {
    position: relative;
    width: calc(33.333% - 10px) !important;
    height: calc(33.333% - 10px) !important;
  }

  .shade9 {
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000;
    }
  }
}
.avue-draggable__item > div {
  width: 100%;
  height: 100%;
}
// 关闭按钮样式
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  z-index: 11;
  &:hover {
    background: url("/img/source/win-close.png") center 0 no-repeat;
    background-size: 100% 100%;
  }
}
/deep/ .player-poster {
  z-index: unset !important;
}
/* 播放视频撑满窗口 */
/deep/ video[data-html5-video] {
  padding: 2px;
  border: 2px solid #032f80;
  object-fit: cover;
  object-position: center center;
  box-sizing: border-box;
}
::v-deep .top {
  padding: unser !important;
}
</style>
