<template>
  <div class="content">
    <div class="pj-select">
      <el-input class="search"
                v-model="searchValue"
                placeholder="请输入项目名称"
                suffix-icon="el-icon-search"
                clearable>
        <!--<div style="height: 100%; padding-right: 5px; display: flex;" slot="suffix"><i class="el-icon-search" style="margin: auto"></i></div>-->
      </el-input>
      <div class="list">
        <p v-for="(project, index) in showProjectList"
           :key="index"
           class="cell">
          <img src="../../assets/pj-item-pre.png"
               alt="">
          <a style="cursor:pointer"
             @click="viewBord(project.id)">{{project.simple}}</a>
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {
  getProjectListByCreateUser,
  getRNBProjectListByCreateUser
} from '@/api/erp'
import { setStore, getStore } from '@/utils/store'

export default {
  data() {
    return {
      bordTypeList: [
        '1528973677142634498', // 项目综合看板
        '1529743932630659074', // 项目经营看板
        '1529743541931241474', // 项目劳务看板
        '1528976646164606977', // 项目设备看板
        '1528967431748481026', // 项目物料看板
        '1541597388928610306', // 项目质量安全看板
        '1578589263728111618', // 塔吊监测
        '1578589578678398978', // 升降机监测
        '1578590142359302146', // 卸料平台监测
        '1578588847858675713', // 环境监测
        '1578590294281187330', // 智能水电
        '1584385704832692225', // 视频监控
        '1581909323464994817', // AI视频监测
        '1581844131980931073', // 劳务实名制
        '1581916498199474177', // 深基坑监测
        '1582683759856218113', // 高支模监测
        '1583001627248455681', // 智能地磅
        '1641393692679868417', //仓库看板
        '1654364445083701249', //云监控
		'1660821912931577858' //安全帽看板
      ],
      projectList: [],
      bordType: 0,
      createUser: null,
	  companyId: null,
      searchValue: '',
      userName: '',
      // pwd: '',
      tenantId: '',
	  key:'',
    }
  },
  async created() {
    // 获取URL参数
    // var query = this.$route.query;
    let query = window.location.search.substring(1)
    let windowParams = {}
    query = query.split('&')
    query.forEach((ele) => {
      let pair = ele.split('=')
      if (pair[0]) {
        windowParams[pair[0]] = pair[1]
      }
    })
    if (!windowParams['createUser']) {
      // this.$message({ showClose: false, message: "缺少必要参数！", type: "error" });
      return
    }
    this.createUser = windowParams['createUser']
	this.companyId = windowParams['companyId']
    this.userName = windowParams['username']
    // this.pwd = windowParams['password']
	this.key = windowParams['key']
    this.tenantId = windowParams['tenantId']
    this.bordType = windowParams['bordType']
    localStorage.setItem('userName', JSON.stringify(this.userName))
    // localStorage.setItem('pwd', JSON.stringify(this.pwd))
    localStorage.setItem('tenantId', JSON.stringify(this.tenantId))
    // URL参数中看板类型和用户ID不存在时提示错误信息
    if (
      this.createUser == null ||
      this.createUser == undefined ||
      this.bordType == null ||
      this.bordType == undefined
    ) {
      this.$message({
        showClose: false,
        message: '参数错误，请从正确渠道访问本页面！',
        type: 'error'
      })
      return
    } else {
      // 获取看板类型
      // 如果看板类型是数字
      if (this.bordType.match('^\\d+$')) {
        // 转换看板类型为数值
        const type = parseInt(this.bordType)
        // 如果看板类型在看板List里不存在
        if (this.bordTypeList.length <= type) {
          this.$message({
            showClose: false,
            message: '您要查看的看板不存在，请确认后重新选择！',
            type: 'error'
          })
          return
        } else {
          // 查看客户端是否已经有看板访问记录
          const obj = getStore({
            name:
              'huizhuyun_bibord_default_project_' +
              this.createUser +
              '_' +
              this.bordType
          })
          // 如果有直接跳转到对应项目的看板
          if (obj) {
            this.viewBord(obj)
          }
        }
        // 如果看板类型不是数字，提示错误消息
      } else {
        this.$message({
          showClose: false,
          message: '参数错误，请从正确渠道访问本页面！',
          type: 'error'
        })
        return
      }
      let bordType = this.$route.query.bordType
      let response = null
      // 如果在数据中心导航页面点击的是劳务实名制，则获取劳务实名制的项目列表
      if (bordType == 13) {
        response = await getRNBProjectListByCreateUser({
          createUser: this.createUser
        })
      } else {
        response = await getProjectListByCreateUser({
          createUser: this.createUser,
		  companyId: this.companyId,
        })
      }
      // 根据用户ID获取用户项目列表
      if (response.status == 200 && response.data.code == 200) {
        this.projectList = response.data.data
        // 如果该用户没有参与任何项目，提示错误信息
        if (this.projectList.length == 0) {
          this.$message({
            showClose: false,
            message: '您没有参与到任何项目，请向公司管理人员确认！',
            type: 'error'
          })
          return
        }
      } else {
        // 接口错误提示
        this.$message({
          showClose: false,
          message: '服务连接失败，请重试或联系管理员！',
          type: 'error'
        })
        return
      }
    }
  },
  computed: {
    showProjectList() {
      return this.projectList.filter((item) =>
        item.simple.includes(this.searchValue)
      )
    }
  },
  methods: {
    /**
     * @param 项目ID
     * 看板跳转处理
     */
    viewBord(projectId) {
      // 根据看板类型获取看板ID
      const bordTypeSend = this.bordTypeList[this.bordType]
      // 将项目ID存储到本地Storage
      setStore({
        name:
          'huizhuyun_bibord_default_project_' +
          this.createUser +
          '_' +
          this.bordType,
        content: projectId
      })
      // 跳转到指定看板
      if (bordTypeSend === '1641393692679868417') {
        this.$router.replace({
          path: '/view/' + bordTypeSend,
          query: {
            projectId: projectId,
            createUser: this.createUser,
            username: this.userName,
            // password: this.pwd,
            tenantId: this.tenantId,
            grant_type: 'password',
            scope: 'all',
            companyId: this.companyId, 
            type: 'account',
			key:this.key
          }
        })
      } else {
        this.$router.replace({
          path: '/view/' + bordTypeSend,
          query: { projectId: projectId, createUser: this.createUser,companyId: this.companyId }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
.content {
  height: 100%;
  background-image: url('../../assets/pj-bg.png');
  background-size: 100% 100%;
  display: flex;

  .pj-select {
    margin: auto;
    width: 363px;
    height: 72vh;
    background-image: url('../../assets/pj-select-bg.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    .search {
      width: 80%;
      margin: 30px;
      background-image: url('../../assets/pj-search-bg.png');
      background-size: 100% 100%;
    }
    .list {
      flex: 1;
      padding: 0 22px;
      overflow-y: scroll;
      .cell {
        min-height: 40px;
        display: flex;
        align-items: flex-start;
        img {
          width: 25px;
          height: 25px;
        }
        a {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
<style scoped scss>
.pj-select .search input {
  background-color: transparent !important;
}
</style>
