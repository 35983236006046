<!-- 萤石云播放器(定制)配置 -->
<template>
  <div>
    <el-form-item label="窗口数量">
      <avue-select
        v-model="main.activeOption.amount"
        :dic="dicOption.videoPlayAmount"
      ></avue-select>
    </el-form-item>
  </div>
</template>

<script>
import { dicOption } from "@/option/config";
export default {
  data() {
    return {
      dicOption: dicOption,
    };
  },
  inject: ["main"],
};
</script>

<style></style>
