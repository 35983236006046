<template>
  <div class="middle">
    <div id="wrapper"
         class="wrapper"
         @mousedown="contain.handleMouseDown">
      <div class="content"
           id="content"
           ref="content"
           :style="contentStyle">
        <div class="container"
             :style="styleName"
             id="container"
             ref="container">
          <div class="grade"
               v-if="gradeFlag || contain.config.gradeShow"
               :style="gradeLenStyle"></div>
          <subgroup ref="subgroup"
                    :nav="contain.list"></subgroup>
        </div>
      </div>
    </div>
    <!--<Dialog ref="myDialog" :config="dialogConfig">-->
    <!--<component ref="avComponent" :is="variElScrollbarable"></component>-->
    <!--</Dialog>-->
    <Dialog ref="projectDialog"
            :config="projectDialogConfig">
      <TopOrgSwitch></TopOrgSwitch>
    </Dialog>
    <!--<J6Dialog class="j6-dialog" ref="j6Dialog" :config="j6DialogConfig">-->
    <!--&lt;!&ndash;<component ref="j6cRef" :is="j6cComponent" @close="close6jDialog"></component>&ndash;&gt;-->
    <!--</J6Dialog>-->
    <J6Dialog2 class="j6-dialog"
               ref="j6Dialog"
               :config="j6DialogConfig">
      <iframe ref="Iframe"
              style="width: 100%; border: none;vertical-align: middle;"
              :style="{'height': j6DialogConfig.height}"
              :src="j6DialogConfig.src"
              draggable="false"></iframe>
      <div v-if="loading"
           class="iframe-mask"
           v-loading="loading"
           element-loading-text="加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.2)"></div>
      <i style="position: absolute; right: 33px; top: 33px; z-index: 9; font-size: 22px; color: #4A95FF; cursor:pointer"
         class="el-icon-close"
         @click="close6jDialog"></i>

      <div style="position: absolute; left: 50%;width: 129px;height: 40px;margin-left: -65px;background-color: #303968;border: 1px solid #d1d2dd;color: #fff;line-height: 40px;text-align: center; font-size: 16px; bottom: 20px; z-index: 9; cursor:pointer"
           @click="close6jDialog">关闭</div>
      <!--<component ref="j6cRef" :is="j6cComponent" @close="close6jDialog"></component>-->
    </J6Dialog2>
    <ProjectsList ref="projectsList"></ProjectsList>
    <SmartBoardList ref="smartBoardList"></SmartBoardList>
    <CompanyList ref="companyList"></CompanyList>
    <!--InventoryMoneyChartDialogContent-->

  </div>
</template>

<script>
import subgroup from './subgroup'
import { config as defaultConfig } from '@/option/config'
import { getObj } from '@/api/visual'
import Vue from 'vue'
import Dialog from './dialog.vue'
// import J6Dialog from './j6dialog.vue'
import J6Dialog2 from './j6dialog2.vue'
import ProjectsList from './projectsList.vue'
import SmartBoardList from './SmartBoardList.vue'
import CompanyList from './companyList.vue'
import TopOrgSwitch from '../../components/common/topOrgSwitch.vue'
import { obj2qs } from '@/utils/utils'
import { getQueryString } from '@/utils/utils2'
// import InventoryMoneyChartDialogContent from '../../components/common/InventoryMoneyChartDialogContent.vue'
// import ContractMoneyChartDialogContent from '../../components/common/ContractMoneyChartDialogContent.vue'
// import CommonBarChartDialogContent from '../../components/common/CommonBarChartDialogContent.vue'
// import store from './../../store/index'
Vue.prototype.$website = window.$website

let self = this
export default {
  name: 'contents',
  inject: ['contain'],
  props: {
    option: Object,
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    wscale: Number
  },
  provide() {
    return {
      contain: this.contain,
      container: this
    }
  },
  components: {
    subgroup,
    Dialog,
    TopOrgSwitch,
    // InventoryMoneyChartDialogContent,
    // ContractMoneyChartDialogContent,
    // CommonBarChartDialogContent,
    // J6Dialog,
    J6Dialog2,
    ProjectsList,
    SmartBoardList,
    CompanyList,
    avCrud: {
      template: `<avue-crud ref="avCrud" :data="temAvData" :option="avOption" :page.sync="page" @size-change="sizeChange"
  @current-change="currentChange">
                <template slot="jbxxForm">
                  <avue-crud :option="avCrudGroupJbxxOption" :data="temAvJbxxData"></avue-crud>
                </template>
                <template slot-scope="{row,index}" slot="menu">
                    <el-button type="text" icon="el-icon-view" size="small" @click.stop="rowView(row,index)">查看</el-button>
                </template>
            </avue-crud>`,
      data: () => {
        return {
          page: self.avPage,
          jbxxPage: self.avCrudGroupJbxxPage,
          avOption: self.avCrudOption,
          avCrudGroupJbxxOption: self.avCrudGroupJbxxOption
        }
      },
      computed: {
        temAvData() {
          return self.avData
        },
        temAvJbxxData() {
          return self.avCrudGroupJbxxData
        }
      },
      methods: {
        rowView(row, index) {
          self.avCrudRowView(row, index)
        },
        sizeChange(val) {
          self.avPage.currentPage = 1
          self.avPage.pageSize = val
          self.getAvCrudData()
        },
        currentChange(val) {
          self.avPage.currentPage = val
          self.getAvCrudData()
        }
      }
    },
    avForm: {
      template:
        '<avue-form ref="avForm" :option="avOption" v-model="obj"></avue-form>',
      data: () => {
        return {
          obj: {},
          avOption: self.avFormOption
        }
      }
    }
  },
  data() {
    return {
      contentStyle: {},
      selectCount: {
        x1: null,
        x2: null,
        y1: null,
        y2: null
      },
      scale: 1,
      gradeFlag: false,
      projectDialogConfig: {
        // top: "13vh",
        title: '项目切换',
        width: '60%',
        btnTxt: ['关闭', '确定']
      },
      dialogConfig: {},
      avPage: {},
      avQuery: {},
      avData: [],
      avCrudOption: {},
      avFormOption: {},
      variable: '', // 动态模板默认显示的id

      secondParams: {}, // 一级穿透,行查看自定义参数。参数必须是行中自有字段。格式{后端接受的参数:行prop}
      avCrudGroupJbxxPage: {},
      avCrudGroupJbxxQuery: {},
      avCrudGroupJbxxOption: {},
      avCrudGroupJbxxData: [], // 查看-表格数据
      j6cComponent: '', //6局项目dialog模板
      j6DialogConfig: {
        width: '1202px',
        height: '663px',
        src: ''
      },
      loading: true
    }
  },
  computed: {
    stepScale() {
      let scale = Number(100 / (this.scale * this.wscale)).toFixed(2)
      return scale
    },
    //计算中央可视化大屏比例
    styleName() {
      let pathname = window.location.pathname
      let _height = 0
      const scale = this.contain.config.scale
      const widthVal = scale / 100 + 0.001
      const heightVal =
        document.body.clientHeight / this.contain.config.height - 0.002
        _height = Math.max(
        this.contain.config.height,
        document.body.clientHeight / widthVal
      )
      if(pathname.indexOf('1648883738009931777')!== -1){
        _height = '108%'
      }
      return Object.assign(
        {
          transform: `scale(${widthVal}, ${widthVal})`,
          width: this.setPx(this.contain.config.width),
          //height: this.setPx(this.contain.config.height),
          height: this.setPx(_height),
          backgroundColor: this.contain.config.backgroundColor
        },
        (() => {
          if (this.contain.config.backgroundImage) {
            return {
              background: `url(${this.contain.config.backgroundImage}) 0% 0% / 100% 100% rgb(3, 12, 59, 0)`
            }
          }
          return
        })()
      )
    },
    gradeLenStyle() {
      return {
        backgroundSize: `${this.setPx(
          this.contain.config.gradeLen
        )} ${this.setPx(this.contain.config.gradeLen)},${this.setPx(
          this.contain.config.gradeLen
        )} ${this.setPx(this.contain.config.gradeLen)}`
      }
    },
    isBuild() {
      return this.$route ? this.$route.name === 'build' : false
    }
  },
  created() {
    self = this
  },
  beforeDestroy() {
    this.$bus.$off('showDialog')
    this.$bus.$off('showDialog2')
  },
  mounted() {
    let _self = this
    _self.initData()
    _self.initFun()

    // _self.$store.commit('SET_MYDIALOG', { a: 1 })
    _self.$bus.$on('showDialog', function (params, refs) {
      
      //是都6j项目
      if (params.data && params.data.is6j == true) {
        // _self.j6cComponent = params.data.component;
        _self.j6DialogConfig.width = params.data.width + 'px'
        _self.j6DialogConfig.height = params.data.height + 'px'
        let appendParam = params.data.params || {}
        appendParam['projectId'] = window.$glob.params['projectId']
        let appendParamsStr = obj2qs(appendParam)
        _self.j6DialogConfig.src = `/view/${params.data.id}?${appendParamsStr}`
        _self.$refs.j6Dialog.open()
        const attachVal = params.data.attachVal
        _self.iframeLoad(attachVal)
        return
      }
      if (params.data && params.data.isProj == true) {
        // 添加项目切换权限, 权限标识 projectChangePurview : boolean
        if(getQueryString('projectChangePurview') !== 'false'){
          _self.$refs.projectsList.show()
        }
      }
      // 点击的是否为智慧工地标题下拉剪头
      if (params.data && params.data.isSmartBoard == true) {
        // 添加智慧工地标题下拉权限, 权限标识 smartBoardPurview : boolean
        if(getQueryString('smartBoardPurview') !== 'false'){
          _self.$refs.smartBoardList.show()
        }
      }
      // 判断点击事件是否以弹窗形式
      if ((params.data && params.data.isDialog) || params.isDialog) {
        // 添加穿透权限, 权限标识 dataPurview : boolean
        if(getQueryString('dataPurview') !== 'false'){
           _self.initPenetrate({ params, refs })
        }
      }
      //   params.isBigTitle = true;
      //  // 执行配置参数传递
      //  this.$bus.$emit("showDialog", params,refs)
      // 判断点击事件是否-切换项目
      if ((params.data && params.data.isBigTitle) || params.isBigTitle) {
        _self.initProjectChange({ params, refs })
      }
    })
    _self.$bus.$on('showDialogAndRefresh', function (params, refs) {
      _self.close6jDialog()
      setTimeout(()=> {
          //是都6j项目
          if (params.data && params.data.is6j == true) {
            // _self.j6cComponent = params.data.component;
            _self.j6DialogConfig.width = params.data.width + 'px'
            _self.j6DialogConfig.height = params.data.height + 'px'
            let appendParam = params.data.params || {}
            appendParam['projectId'] = window.$glob.params['projectId']
            let appendParamsStr = obj2qs(appendParam)
            _self.j6DialogConfig.src = `/view/${params.data.id}?${appendParamsStr}`
            _self.$refs.j6Dialog.open()
            const attachVal = params.data.attachVal
            _self.iframeLoad(attachVal)
            return
          }
          if (params.data && params.data.isProj == true) {
            _self.$refs.projectsList.show()
          }
          // 点击的是否为智慧工地标题下拉剪头
          if (params.data && params.data.isSmartBoard == true) {
            _self.$refs.smartBoardList.show()
          }
          // 判断点击事件是否以弹窗形式
          if ((params.data && params.data.isDialog) || params.isDialog) {
            _self.initPenetrate({ params, refs })
          }
          //   params.isBigTitle = true;
          //  // 执行配置参数传递
          //  this.$bus.$emit("showDialog", params,refs)
          // 判断点击事件是否-切换项目
          if ((params.data && params.data.isBigTitle) || params.isBigTitle) {
            _self.initProjectChange({ params, refs })
          }
        }, 500)

    })
    _self.$bus.$on('showDialog2', function (params, refs) {
      //是都6j项目
      if (params.data && params.data.is6j == true) {
        // _self.j6cComponent = params.data.component;
        _self.j6DialogConfig.width = params.data.width + 'px'
        _self.j6DialogConfig.height = params.data.height + 'px'
        let appendParam = params.data.params || {}
        appendParam['companyId'] = window.$glob.params['companyId']
        appendParam['createUser'] = window.$glob.params['createUser']
        let appendParamsStr = obj2qs(appendParam)
        _self.j6DialogConfig.src = `/view/${params.data.id}?${appendParamsStr}`
        _self.$refs.j6Dialog.open()

        _self.iframeLoad()
        return
      }
      if (params.data && params.data.isProj == true) {
        _self.$refs.companyList.show()
      }
      // 判断点击事件是否以弹窗形式
      if ((params.data && params.data.isDialog) || params.isDialog) {
        _self.initPenetrate({ params, refs })
      }
      //   params.isBigTitle = true;
      //  // 执行配置参数传递
      //  this.$bus.$emit("showDialog", params,refs)
      // 判断点击事件是否-切换项目
      if ((params.data && params.data.isBigTitle) || params.isBigTitle) {
        _self.initProjectChange({ params, refs })
      }
    })
    _self.$bus.$on('closeDialog', function (params, refs) {
      _self.close6jDialog(params,refs)
    })
  },
  methods: {
    iframeLoad(attachVal) {
      this.loading = true
      this.$nextTick(() => {
        const iframe = this.$refs.Iframe
        // iframe.src = this.j6DialogConfig.src;
        iframe.contentWindow.location.reload(true)
        if (iframe.attachEvent) {
          // IE
          iframe.attachEvent('onload', () => {
            this.loading = false
          })
        } else {
          // 非IE
          iframe.onload = () => {
            this.loading = false
            let iframeDocument =
              document.getElementsByTagName('iframe')[0].contentDocument
              if(attachVal) {
                iframe.contentWindow.attachVal = attachVal
              }
            iframe.contentDocument.body.style.backgroundColor = 'blue'
          }
        }
      })
    },
    initFun() {
      ['handleRefresh', 'getDragObj'].forEach((ele) => {
        this[ele] = this.$refs.subgroup[ele]
      })
    },
    // dialogBeforClose() {
    //     this.$bus.$off('showDialog');
    // },
    //初始化数据
    initData() {
      const id = this.$route ? this.$route.params.id : this.props.id
      this.contain.id = id
      this.contain.contentWidth = this.$refs.content.offsetWidth
      const width = this.isBuild
        ? this.contain.contentWidth
        : document.body.clientWidth
      let config
      const callback = () => {
        //赋值属性
        let mark = this.contain.config.mark
        if (mark.show && !this.isBuild) {
          this.watermark(
            Object.assign(mark, {
              fontSize: mark.fontSize + 'px'
            })
          )
        }
        this.calcData()
        this.setScale(width)
      }
      if (id) {
        const loading = this.$loading({
          lock: true,
          text: '正在加载中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        getObj(id)
          .then((res) => {
            const data = res.data.data
            this.contain.obj = data
            config = data.config
            const contain = {
              config: JSON.parse(config.detail) || {},
              component: JSON.parse(config.component) || []
            }
            this.contain.config = Object.assign(
              {},
              defaultConfig,
              contain.config
            )
            this.contain.nav = contain.component
            this.contain.visual = data.visual
            // 修改浏览器Title  WangCong 2021.12.22
            document.title =
              data.visual.title + '-' + this.website.companyShortName
            //添加水印。只有查看页面生效
            if (!this.isBuild) {
              const password = this.contain.visual.password
              if (!this.validatenull(password)) {
                this.$prompt('请输入密码', '提示', {
                  confirmButtonText: '确定',
                  showCancelButton: false,
                  showClose: false,
                  closeOnClickModal: false,
                  inputPattern: new RegExp(password),
                  inputErrorMessage: '密码不正确，请重新输入'
                }).then(() => {
                  callback()
                })
              } else {
                callback()
              }
            } else {
              callback()
            }
            loading.close()
          })
          .catch((err) => {
            loading.close()
          })
      } else if (this.option) {
        config = this.option
        this.contain.config = config.detail || {}
        this.contain.nav = config.component || []
        callback()
      } else {
        this.setScale(width)
      }
    },
    // 初始化穿透
    initPenetrate(e) {
      let _self = this
      _self.variable = 'avCrud' // 动态模板默认显示的id
      _self.dialogConfig = {} // 弹窗配置，api见组件
      _self.avQuery = {
        dataMethod: 'get', // 请求方式
        url: '', // 请求地址
        params: {} // 请求参数
      }
      _self.avPage = {
        pageSize: 10, // 页面数据大小
        currentPage: 1 // 当前页
      }
      _self.avData = []
      _self.avCrudOption = {
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        columnBtn: false, // 列筛选 抽屉
        refreshBtn: false, // 表格右侧刷新按钮
        menuWidth: 80, // 定义操作按钮列宽度
        labelWidth: 140 // 整体label宽度
      }
      _self.avFormOption = {}

      //---- 二级穿透配置 start ----
      _self.avCrudGroupJbxxQuery = {
        dataMethod: 'get', // 请求方式
        url: '', // 请求地址
        params: {} // 请求参数
      }
      _self.avCrudGroupJbxxPage = {
        pageSize: 10, // 页面数据大小
        currentPage: 1 // 当前页
      }
      _self.avCrudGroupJbxxData = []
      _self.avCrudGroupJbxxOption = {
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        columnBtn: false, // 列筛选 抽屉
        refreshBtn: false, // 表格右侧刷新按钮
        menu: false
      }
      let { params } = e
      // 弹窗配置
      _self.dialogConfig = Object.assign(
        _self.dialogConfig,
        params.dialogConfig
      )
      // 接口请求配置
      if (params.avQuery) {
        _self.avQuery = Object.assign(_self.avQuery, params.avQuery)
      }

      // 弹窗内容模板名，后期可进行封装遍历模板对其进行优化
      if (params.variable) {
        _self.variable = params.variable
      }
      if (params.secondParams) {
        _self.secondParams = params.secondParams
      }

      if (params.avCrudGroupJbxxQuery) {
        _self.avCrudGroupJbxxQuery = Object.assign(
          _self.avCrudGroupJbxxQuery,
          params.avCrudGroupJbxxQuery
        )
      }

      // crud中是否启用分组功能
      if (params.avCrudGroupJbxxOption) {
        _self.avCrudGroupJbxxOption = Object.assign(
          _self.avCrudGroupJbxxOption,
          params.avCrudGroupJbxxOption
        )
      }
      // 展示普通card页面
      if (_self.variable == 'avCrud') {
        // crud基础字段配置
        if (params.avCrudOption) {
          _self.avCrudOption = Object.assign(
            _self.avCrudOption,
            params.avCrudOption
          )
        }
      }
      // 展示普通form页面
      if (_self.variable == 'avForm') {
        // form基础字段配置
        if (_self.avFormOption) {
          _self.avFormOption = Object.assign(
            _self.avFormOption,
            params.avFormOption
          )
        }
      }
      // 展示设计页面
      if (params.data.doalogViewId) {
        // getObj(params.data.doalogViewId)
      }

      _self.getAvCrudData()
    },
    /** 请求配置api
         * avQuery:{
             dataMethod: xxx, // 请求方式。默认get
                url: xxx, // 请求地址
                params:{}, // 请求参数配置
                dataHeader: {}, // 请求头配置
            }
        * avPage:{
            currentPage: 1, // 当前页
            size: 10, // 当前页数据数量
        }
            **/
    getAvCrudData() {
      let _self = this
      // 穿透页面数据请求
      if (_self.avQuery.url) {
        let params = Object.assign(_self.avQuery.params, {
          current: _self.avPage.currentPage,
          size: _self.avPage.pageSize
        })
        let dataHeader = _self.avQuery.dataHeader || {}
        _self
          .$axios({
            method: _self.avQuery.dataMethod || 'get',
            url: _self.avQuery.url,
            data: params,
            headers: dataHeader,
            params: params
          })
          .then((res) => {
            if (res.status === 200) {
              _self.avData = res.data.data.records || res.data.data
              _self.avPage.total = res.data.data.total || 0
            } else {
              _self.avData = []
            }
            // 打开弹窗
            _self.$refs.myDialog.open()
          })
          .catch((err) => {
          })
      }
    },
    // 表格查看-二级穿透表格
    getAvCrudGroupJbxxData(params) {
      let _self = this
      // 穿透页面数据请求
      if (_self.avCrudGroupJbxxQuery.url) {
        let paramsAll = Object.assign(_self.avCrudGroupJbxxQuery.params, params)
        let dataHeader = _self.avCrudGroupJbxxQuery.dataHeader || {}
        _self
          .$axios({
            method: _self.avCrudGroupJbxxQuery.dataMethod || 'get',
            url: _self.avCrudGroupJbxxQuery.url,
            data: paramsAll,
            headers: dataHeader,
            params: paramsAll
          })
          .then((res) => {
            if (res.status === 200) {
              _self.avCrudGroupJbxxData = res.data.data.records || res.data.data
              _self.avCrudGroupJbxxPage.total = res.data.data.total || 0
            } else {
              _self.avCrudGroupJbxxData = []
            }
          })
          .catch((err) => {
          })
      }
    },
    // 一级穿透表格查看
    avCrudRowView(row, index) {
      let _self = this
      _self.$refs.avComponent.$refs.avCrud.rowView(row, index)
      if (_self.avCrudGroupJbxxOption) {
        // 一级穿透，查看自定义请求参数
        let params = {}
        if (_self.secondParams) {
          for (var key in _self.secondParams) {
            params[key] = row[_self.secondParams[key]]
          }
        }
        _self.getAvCrudGroupJbxxData(params)
      }
    },

    // 项目切换
    initProjectChange(e) {
      let _self = this
      let { params } = e
      // 打开弹窗
      _self.$refs.projectDialog.open()
    },
    //计算比例
    setScale(width) {
      this.contain.config.scale = (width / this.contain.config.width) * 100
      this.scale = this.contain.config.scale
      this.setResize()
    },
    //适配尺寸
    setResize() {
      this.contentStyle = {
        width: this.setPx(
          (this.contain.config.scale * this.contain.config.width) / 100
        ),
        height: this.setPx(
          (this.contain.config.scale * this.contain.config.height) / 100
        )
      }
    },
    calcData() {
      if (!this.contain.config.mark) this.contain.config.mark = {}
      if (!this.contain.config.query) this.contain.config.query = {}
    },
    handlePostionSelect(postion) {
      this.handleCalcPostionSelect()
      const x1 = this.selectCount.x1
      const x2 = this.selectCount.x2
      const y1 = this.selectCount.y1
      const y2 = this.selectCount.y2
      if (postion === 'left') {
        this.handleMoveSelectList(x1, undefined, true, postion)
      } else if (postion === 'center') {
        this.handleMoveSelectList(x1 + (x2 - x1) / 2, undefined, true, postion)
      } else if (postion === 'right') {
        this.handleMoveSelectList(x2, undefined, true, postion)
      } else if (postion === 'top') {
        this.handleMoveSelectList(undefined, y1, true, postion)
      } else if (postion === 'middle') {
        this.handleMoveSelectList(undefined, y1 + (y2 - y1) / 2, true, postion)
      } else if (postion === 'bottom') {
        this.handleMoveSelectList(undefined, y2, true, postion)
      }
    },
    handleMoveSelectList(left, top, type, postion) {
      this.contain.active.forEach((ele) => {
        ele = this.contain.findlist(ele)
        const ele_component = ele.component
        //水平情况
        if (left) {
          let baseLeft = Number(type ? left : (ele.left + left).toFixed(2))
          if (postion === 'right') {
            baseLeft = baseLeft - ele_component.width
          } else if (postion === 'center') {
            const obj_center = ele.left + ele_component.width / 2
            baseLeft = ele.left + (left - obj_center)
          }
          this.$set(ele, 'left', baseLeft)
        }
        //垂直情况
        if (top) {
          let baseTop = Number(type ? top : (ele.top + top).toFixed(2))
          if (postion === 'bottom') {
            baseTop = baseTop - ele_component.height
          } else if (postion === 'middle') {
            const obj_middle = ele.top + ele_component.height / 2
            baseTop = ele.top + (top - obj_middle)
          }
          this.$set(ele, 'top', baseTop)
        }
      })
    },
    //计算多选状态下的最大边界值
    handleCalcPostionSelect() {
      this.selectCount = {
        x1: null,
        x2: null,
        y1: null,
        y2: null
      }
      this.contain.active.forEach((ele) => {
        ele = this.contain.findlist(ele)
        const left = ele.left
        const top = ele.top
        const width = ele.component.width
        const height = ele.component.height
        if (!this.selectCount.x1) {
          this.selectCount = {
            x1: left,
            x2: left + width,
            y1: top,
            y2: top + height
          }
        }
        if (this.selectCount.x1 > left) this.selectCount.x1 = left
        if (this.selectCount.x2 < left + width)
          this.selectCount.x2 = left + width
        if (this.selectCount.y1 > top) this.selectCount.y1 = top
        if (this.selectCount.y2 < top + height)
          this.selectCount.y2 = top + height
      })
    },
    close6jDialog(params,refs) {
      if(typeof (params) !== 'undefined'){
        let currentPage = localStorage.getItem('currentPage')
        let currentPagexm = localStorage.getItem('currentPagexm')
        if(currentPage === 'gongsichukuxinxi' ){
          localStorage.removeItem('currentStoreId')
          localStorage.removeItem('currentStoreName')
          localStorage.removeItem('currentClickIndex')
          localStorage.removeItem('currentFlowList')
          localStorage.removeItem('currentOrderId')
          localStorage.removeItem('currentCheckObj')
          localStorage.removeItem('currentOrderId')
          localStorage.removeItem('localDeleteArray')
          localStorage.removeItem('currentckData')
        }
        if(currentPage === 'gongsipicixinxi' ){
          localStorage.removeItem('localDeleteArray')
          localStorage.removeItem('currentStoreId')
          localStorage.removeItem('currentStoreName')
          localStorage.removeItem('currentClickIndex')
          localStorage.removeItem('currentFlowList')
          localStorage.removeItem('currentOrderId')
          localStorage.removeItem('currentckData')
        }
        if(currentPagexm === 'gongsichukuxinxixm' ){
          localStorage.removeItem('currentStoreIdxm')
          localStorage.removeItem('currentStoreNamexm')
          localStorage.removeItem('currentClickIndexxm')
          localStorage.removeItem('currentFlowListxm')
          localStorage.removeItem('currentOrderIdxm')
          localStorage.removeItem('currentCheckObjxm')
          localStorage.removeItem('currentOrderIdxm')
          localStorage.removeItem('localDeleteArrayxm')
          localStorage.removeItem('currentckDataxm')
        }
        if(currentPagexm === 'gongsipicixinxixm' ){
          localStorage.removeItem('localDeleteArrayxm')
          localStorage.removeItem('currentStoreIdxm')
          localStorage.removeItem('currentStoreNamexm')
          localStorage.removeItem('currentClickIndexxm')
          localStorage.removeItem('currentFlowListxm')
          localStorage.removeItem('currentOrderIdxm')
          localStorage.removeItem('currentckDataxm')
        }
      }
	  localStorage.removeItem('changeDate');
      this.$refs.j6Dialog.cancel()
    }
  }
}
</script>

<style scoped>
.container {
  background-color: transparent;
}
.iframe-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background-image: url('../../assets/dialog-bg.png');
  background-size: 100% 100%;
  border-color: unset !important;
  background-color: unset !important;
}
</style>
